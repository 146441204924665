import React, { useState } from 'react';
import { Card, Row, Col, Tabs, Tab, Table, Accordion, ButtonGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import TradingViewWidget from 'react-tradingview-widget';
import SimpleChart from './SimpleChart';
import DataRoom from './DataRoom';
import '../styles/StockInformation.css';

const StockInformation = ({ stats, bs, videos, transcripts, stockData, onTimeframeChange, currentTimeframe }) => {
    const [selectedTranscript, setSelectedTranscript] = useState('');
    const [showTranscriptModal, setShowTranscriptModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTranscriptKey, setSelectedTranscriptKey] = useState('');
    const [chartView, setChartView] = useState('simple');

    // Add more detailed console logging
    console.log('StockInformation component rendering with:', {
        stats: stats,
        bs: bs,
        videos: videos,
        transcripts: transcripts
    });

    // More specific error checks
    if (!stats) {
        console.error('Stats prop is null or undefined');
        return <p>Loading stock information...</p>;
    }

    if (typeof stats !== 'object') {
        console.error('Stats prop is not an object, received:', typeof stats, stats);
        return <p>Invalid stock information format</p>;
    }

    if (!stats.Name) {
        console.error('Stats object is missing Name property:', stats);
        return <p>No stock information available.</p>;
    }

    console.log('bs prop:', bs);
    console.log('videos prop:', videos);

    // Function to get unique expiry dates
    const getExpiryDates = () => {
        if (!bs || !Array.isArray(bs)) return [];
        return [...new Set(bs.map(contract => contract.expiration))].sort();
    };

    const handleTranscriptClick = async (transcript) => {
        try {
            const response = await axios.get(`/stock/transcript`, {
                params: { key: transcript.key }
            });
            setSelectedTranscript(response.data.content);
            setSelectedTranscriptKey(transcript.key);
            setShowTranscriptModal(true);
        } catch (err) {
            console.error('Error fetching transcript:', err);
        }
    };

    const searchInTranscript = async () => {
        if (!searchTerm) return;
        
        try {
            const response = await axios.get('/stock/transcript/search', {
                params: {
                    query: searchTerm,
                    ticker: transcripts[0].symbol,
                    transcript_key: selectedTranscriptKey
                }
            });

            console.log('Number of matches:', response.data.length);  // Should be 5
            let content = selectedTranscript;
            
            // Make sure we process all chunks
            for (let i = 0; i < response.data.length; i++) {
                const match = response.data[i];
                const snippetToHighlight = match.text.substring(0, 1000)
                    .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                
                const regex = new RegExp(snippetToHighlight, 'g');
                content = content.replace(regex, 
                    `<span style="background-color: yellow">${snippetToHighlight}</span>`
                );
            }
            
            setSelectedTranscript(content);
            
        } catch (err) {
            console.error('Error searching transcript:', err);
        }
    };

    return (
        <Card className="mb-4">
            <Card.Body className="text-start">
                <h2>Stock Information</h2>

                {/* Update button order to match default */}
                <ButtonGroup className="mb-3">
                    <Button 
                        variant={chartView === 'simple' ? 'primary' : 'outline-primary'}
                        onClick={() => setChartView('simple')}
                    >
                        Normal View
                    </Button>
                    <Button 
                        variant={chartView === 'tradingview' ? 'primary' : 'outline-primary'}
                        onClick={() => setChartView('tradingview')}
                    >
                        TradingView
                    </Button>
                </ButtonGroup>

                {/* Update condition to match new default */}
                {chartView === 'simple' ? (
                    <SimpleChart 
                        stockData={stockData} 
                        onTimeframeChange={onTimeframeChange}
                        currentTimeframe={currentTimeframe}
                    />
                ) : (
                    <TradingViewWidget
                        symbol={stats.Symbol}
                        width="100%"
                        height="700"
                        theme="light"
                        interval="D"
                        locale="en"
                        hide_side_toolbar={false}
                    />
                )}

                {/* Tabs for Fundamentals, Options, and Videos */}
                <Tabs defaultActiveKey="fundamentals" id="stock-info-tabs" className="mb-3">
                    <Tab eventKey="fundamentals" title="Fundamentals">
                        {/* Fundamentals Section */}
                        <Row className="mb-3">
                            <Col><p><strong>Company Name:</strong> {stats.Name}</p></Col>
                            <Col><p><strong>Market Cap:</strong> {stats.MarketCapitalization ? `$${(parseFloat(stats.MarketCapitalization) / 1000000000).toFixed(2)}B` : 'N/A'}</p></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><p><strong>Beta:</strong> {stats.Beta}</p></Col>
                            
                            <Col><p><strong>Forward PE:</strong> {stats.ForwardPE}</p></Col>
                            <Col><p><strong>Trailing PE:</strong> {stats.TrailingPE}</p></Col>
                            <Col><p><strong>Dividend Yield:</strong> {stats.DividendYield}</p></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><p><strong>PEG Ratio:</strong> {stats.PEGRatio}</p></Col>
                            <Col><p><strong>Price to Book:</strong> {stats.PriceToBookRatio}</p></Col>
                            <Col><p><strong>Quarterly Revenue Growth Y/Y:</strong> {stats.QuarterlyRevenueGrowthYOY}</p></Col>
                            <Col><p><strong>EBITDA:</strong> {stats.EBITDA ? `$${(parseFloat(stats.EBITDA) / 1000000000).toFixed(2)}B` : 'N/A'}</p></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><p><strong>Description:</strong> {stats.Description}</p></Col>
                        </Row>
                    </Tab>

                    <Tab eventKey="options" title="Options">
                        {/* Options Section */}
                        {bs && Array.isArray(bs) ? (
                            <Accordion>
                                {getExpiryDates().map((expiry, idx) => (
                                    <Accordion.Item key={idx} eventKey={idx.toString()}>
                                        <Accordion.Header>
                                            {new Date(expiry).toLocaleDateString()}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Tabs defaultActiveKey="calls" className="mb-3">
                                                <Tab eventKey="calls" title="Calls">
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Strike</th>
                                                                <th>Last</th>
                                                                <th>Bid</th>
                                                                <th>Bid Size</th>
                                                                <th>Ask</th>
                                                                <th>Ask Size</th>
                                                                <th>Mark</th>
                                                                <th>Volume</th>
                                                                <th>Open Int</th>
                                                                <th>IV</th>
                                                                <th>Delta</th>
                                                                <th>Gamma</th>
                                                                <th>Theta</th>
                                                                <th>Vega</th>
                                                                <th>Rho</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {bs
                                                                .filter(contract => 
                                                                    contract.expiration === expiry && 
                                                                    contract.type === 'call'
                                                                )
                                                                .sort((a, b) => parseFloat(a.strike) - parseFloat(b.strike))
                                                                .map((contract, index) => (
                                                                    <tr key={index}>
                                                                        <td>${contract.strike}</td>
                                                                        <td>${contract.last}</td>
                                                                        <td>${contract.bid}</td>
                                                                        <td>{contract.bid_size}</td>
                                                                        <td>${contract.ask}</td>
                                                                        <td>{contract.ask_size}</td>
                                                                        <td>${contract.mark}</td>
                                                                        <td>{contract.volume}</td>
                                                                        <td>{contract.open_interest}</td>
                                                                        <td>{parseFloat(contract.implied_volatility).toFixed(2)}%</td>
                                                                        <td>{parseFloat(contract.delta).toFixed(3)}</td>
                                                                        <td>{parseFloat(contract.gamma).toFixed(4)}</td>
                                                                        <td>{parseFloat(contract.theta).toFixed(4)}</td>
                                                                        <td>{parseFloat(contract.vega).toFixed(4)}</td>
                                                                        <td>{parseFloat(contract.rho).toFixed(4)}</td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                                </Tab>
                                                <Tab eventKey="puts" title="Puts">
                            <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Strike</th>
                                                                <th>Last</th>
                                                                <th>Bid</th>
                                                                <th>Bid Size</th>
                                                                <th>Ask</th>
                                                                <th>Ask Size</th>
                                                                <th>Mark</th>
                                                                <th>Volume</th>
                                                                <th>Open Int</th>
                                                                <th>IV</th>
                                                                <th>Delta</th>
                                                                <th>Gamma</th>
                                                                <th>Theta</th>
                                                                <th>Vega</th>
                                                                <th>Rho</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {bs
                                                                .filter(contract => 
                                                                    contract.expiration === expiry && 
                                                                    contract.type === 'put'
                                                                )
                                                                .sort((a, b) => parseFloat(a.strike) - parseFloat(b.strike))
                                                                .map((contract, index) => (
                                                                    <tr key={index}>
                                                                        <td>${contract.strike}</td>
                                                                        <td>${contract.last}</td>
                                                                        <td>${contract.bid}</td>
                                                                        <td>{contract.bid_size}</td>
                                                                        <td>${contract.ask}</td>
                                                                        <td>{contract.ask_size}</td>
                                                                        <td>${contract.mark}</td>
                                                                        <td>{contract.volume}</td>
                                                                        <td>{contract.open_interest}</td>
                                                                        <td>{parseFloat(contract.implied_volatility).toFixed(2)}%</td>
                                                                        <td>{parseFloat(contract.delta).toFixed(3)}</td>
                                                                        <td>{parseFloat(contract.gamma).toFixed(4)}</td>
                                                                        <td>{parseFloat(contract.theta).toFixed(4)}</td>
                                                                        <td>{parseFloat(contract.vega).toFixed(4)}</td>
                                                                        <td>{parseFloat(contract.rho).toFixed(4)}</td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                            </Table>
                                                </Tab>
                                            </Tabs>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        ) : (
                            <p>No options data available.</p>
                        )}
                    </Tab>

                    {/* New Videos Tab */}
                    <Tab eventKey="videos" title="Videos">
                        {videos && videos.length > 0 ? (
                            <div>
                                {videos.map((video, index) => (
                                    <div key={index} className="mb-4">
                                        <h5>{video.title}</h5>
                                        <p>
                                            <strong>Published:</strong> {video.published} by {video.author}<br />
                                            <strong>Views:</strong> {video.views}<br />
                                            <strong>Duration:</strong> {video.duration}
                                        </p>
                                        <div className="video-container">
                                            <iframe
                                                src={`https://www.youtube.com/embed/${video.id}`}
                                                title={video.title}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No videos available.</p>
                        )}
                    </Tab>

                    <Tab eventKey="transcripts" title="Transcripts">
                        {transcripts && transcripts.length > 0 ? (
                            <div>
                                {transcripts.map((transcript, index) => (
                                    <div key={index} className="mb-3">
                                        <h5>Q{transcript.quarter} {transcript.year} Earnings Call</h5>
                                        <p>
                                            <strong>Last Modified:</strong> {new Date(transcript.last_modified).toLocaleDateString()}<br />
                                            <button 
                                                onClick={(e) => {
                                                    handleTranscriptClick(transcript)
                                                }}
                                                style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                            >
                                                View Transcript
                                            </button>
                                        </p>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No transcripts available.</p>
                        )}
                    </Tab>

                    {/* Add new Data Room tab */}
                    <Tab eventKey="dataroom" title="Data Room">
                        <DataRoom symbol={stats.Symbol} />
                    </Tab>
                </Tabs>

                {/* Modal for displaying transcript */}
                <Modal show={showTranscriptModal} onHide={() => setShowTranscriptModal(false)} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="d-flex align-items-center gap-3">
                                Earnings Call Transcript
                                <div className="d-flex align-items-center">
                                    <input
                                        type="text"
                                        placeholder="Search in transcript..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        onKeyPress={(e) => e.key === 'Enter' && searchInTranscript()}
                                        className="form-control"
                                        style={{ width: '200px' }}
                                    />
                                    <button 
                                        onClick={searchInTranscript}
                                        className="btn btn-primary ms-2"
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{ __html: selectedTranscript }} />
                    </Modal.Body>
                </Modal>
            </Card.Body>
        </Card>
    );
};

export default StockInformation;
