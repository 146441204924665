import React, { useState, useEffect } from 'react';
import axios from 'axios';
import StockInformation from './StockInformation';
import RecentNews from './RecentNews';
import ChatBox from './ChatBox';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

const StockSearch = () => {
    const [ticker, setTicker] = useState('');
    const [inputTicker, setInputTicker] = useState('');
    const [stockInfo, setStockInfo] = useState(null);
    const [stockData, setStockData] = useState(null);
    const [analysisText, setAnalysisText] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentTimeframe, setCurrentTimeframe] = useState('1y');

    const fetchStockData = async (symbol, tf = '1y') => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`/stock/${symbol}?timeframe=${tf}`);
            console.log(`API Response for ${symbol} (${tf}):`, response.data);

            setStockData(response.data.stock_data);
            setStockInfo(response.data);
            setAnalysisText(response.data.analysis_text);
            setCurrentTimeframe(tf);
        } catch (error) {
            console.error(`Error fetching stock data for ${symbol} (${tf}):`, error);
            setError(`Failed to fetch stock data for ${symbol}`);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (event) => {
        event.preventDefault();
        if (inputTicker) {
            const upperCaseTicker = inputTicker.toUpperCase();
            setTicker(upperCaseTicker);
            fetchStockData(upperCaseTicker, '1y');
            setCurrentTimeframe('1y');
        }
    };

    const handleTimeframeChange = (newTimeframe) => {
        if (ticker && newTimeframe !== currentTimeframe) {
            console.log(`StockSearch: Timeframe changed to ${newTimeframe} for ${ticker}`);
            fetchStockData(ticker, newTimeframe);
        }
    };

    useEffect(() => {
        // Prevent appending the script multiple times
        if (!ticker && !document.querySelector('.tradingview-widget-script')) {
            const script = document.createElement('script');
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js';
            script.async = true;
            script.className = 'tradingview-widget-script'; // Add a class to avoid multiple additions
            script.innerHTML = JSON.stringify({
                "width": "100%",
                "height": 410,
                "symbolsGroups": [
                    {
                        "name": "Indices",
                        "originalName": "Indices",
                        "symbols": [
                            { "name": "FOREXCOM:SPXUSD", "displayName": "S&P 500 Index" },
                            { "name": "FOREXCOM:NSXUSD", "displayName": "US 100 Cash CFD" },
                            { "name": "FOREXCOM:DJI", "displayName": "Dow Jones Industrial Average Index" }
                        ]
                    },
                    {
                        "name": "Futures",
                        "originalName": "Futures",
                        "symbols": [
                            { "name": "CME_MINI:ES1!", "displayName": "S&P 500" },
                            { "name": "COMEX:GC1!", "displayName": "Gold" }
                        ]
                    },
                    {
                        "name": "Forex",
                        "originalName": "Forex",
                        "symbols": [
                            { "name": "FX:EURUSD", "displayName": "EUR to USD" },
                            { "name": "FX:GBPUSD", "displayName": "GBP to USD" }
                        ]
                    }
                ],
                "showSymbolLogo": true,
                "isTransparent": false,
                "colorTheme": "light",
                "locale": "en",
                "backgroundColor": "#ffffff"
            });
            document.querySelector('.tradingview-widget-container__widget').appendChild(script);
        }
    }, [ticker]);

    useEffect(() => {
        // Set up EventSource for streaming data
        const eventSource = new EventSource('/test-data/stream');
        
        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data) {
                setAnalysisText(prev => prev + ' ' + data);
            }
        };
        
        eventSource.onerror = () => {
            eventSource.close();
        };
        
        return () => {
            eventSource.close();
        };
    }, []);

    console.log('stockData in StockSearch:', stockData);

    return (
        <Container fluid className="p-4" style={{ marginTop: '0.25in' }}>
            <Row>
                <Col md={3}>
                    <Form onSubmit={handleSearch}>
                        <Form.Group className="mb-3 d-flex">
                            <Form.Control
                                type="text"
                                placeholder="Enter stock ticker"
                                value={inputTicker}
                                onChange={(e) => setInputTicker(e.target.value)}
                                className="me-2"
                            />
                            <Button variant="primary" type="submit" disabled={loading}>
                                {loading ? 'Loading...' : 'Search'}
                            </Button>
                        </Form.Group>
                    </Form>
                    {error && <p className="text-danger">{error}</p>}
                </Col>
            </Row>

            {!ticker && (
                <>
                    <Row className="mt-4">
                        <Col>
                            <div className="p-3 bg-light border rounded" style={{ height: '275px' }}>
                                <p className="mb-0">{analysisText}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <div className="tradingview-widget-container" style={{ overflow: 'hidden' }}>
                                <div className="tradingview-widget-container__widget"></div>
                            </div>
                        </Col>
                    </Row>
                </>
            )}

            <Row className="mt-4">
                <Col md={8}>
                    {ticker && stockInfo && (
                        <StockInformation
                            stats={stockInfo?.stats}
                            bs={stockInfo?.bs}
                            videos={stockInfo?.videos}
                            transcripts={stockInfo?.transcripts}
                            stockData={stockData}
                            onTimeframeChange={handleTimeframeChange}
                            currentTimeframe={currentTimeframe}
                            key={ticker + currentTimeframe}
                        />
                    )}
                </Col>

                <Col md={4}>
                    {ticker && <ChatBox ticker={ticker} analysisText={analysisText} />}
                </Col>
            </Row>

            {stockInfo && (
                <Row className="mt-1">
                    <Col md={8} className="text-start">
                        <RecentNews newsData={stockInfo?.news_data} />
                    </Col>
                    <Col md={4} className="text-start">
                        {analysisText && (
                            <div>
                                <h3>Stock Discussion</h3>
                                <div
                                    className="reddit-comments"
                                    style={{
                                        wordBreak: 'normal !important',
                                        whiteSpace: 'normal !important',
                                        overflowWrap: 'break-word! important',
                                        lineHeight: '1.5',
                                        maxWidth: '600px',
                                        margin: '0 auto',
                                    }}
                                >
                                    {analysisText.split('\n').map((line, index) => (
                                        <p key={index}>{line}</p>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default StockSearch;
