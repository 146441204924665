import React, { useState } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { ButtonGroup, Button } from 'react-bootstrap';

// Accept currentTimeframe prop, remove local selectedTimeframe state
const SimpleChart = ({ stockData, onTimeframeChange, currentTimeframe }) => {
  // Remove local state: const [selectedTimeframe, setSelectedTimeframe] = useState('1y');

  // Format dates for better display in the chart tooltip/axis
  const formattedData = stockData?.map(item => ({
    ...item,
    // Keep Date as string YYYY-MM-DD for recharts time axis if needed, 
    // or format here if only for display
    Date: item.Date // Assuming Date is already YYYY-MM-DD string from backend
    // Date: new Date(item.Date).toLocaleDateString() // Use this if you prefer MM/DD/YYYY display
  }));

  const handleTimeframeClick = (timeframe) => {
    console.log(`SimpleChart: Button ${timeframe} clicked`);
    // No need to set local state anymore
    if (onTimeframeChange) {
        onTimeframeChange(timeframe);
    } else {
        console.warn("SimpleChart: onTimeframeChange prop is missing!");
    }
  };

  return (
    <div>
      <ButtonGroup className="mb-3">
        {['1m', '3m', '6m', '1y', '5y'].map((tf) => (
          <Button
            key={tf}
            // Use the currentTimeframe prop for highlighting
            variant={currentTimeframe === tf ? 'primary' : 'outline-primary'}
            onClick={() => handleTimeframeClick(tf)}
          >
            {tf.toUpperCase()}
          </Button>
        ))}
      </ButtonGroup>

      {/* Add a check for data before rendering chart */}
      {formattedData && formattedData.length > 0 ? (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={formattedData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="Date" 
              // Consider adding tickFormatter for dates if needed
            />
            <YAxis domain={['auto', 'auto']} />
            <Tooltip />
            <Line 
              type="monotone" 
              dataKey="Close" 
              stroke="#8884d8" 
              dot={false}
              strokeWidth={2}
              isAnimationActive={false} // Keep animation off
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <p>Loading chart data or no data available...</p>
        </div>
      )}
    </div>
  );
};

export default SimpleChart; 