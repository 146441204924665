import React, { useState } from 'react';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Button } from 'react-bootstrap';

function CameraButton({ symbol, onPhotoTaken }) {
  const [photo, setPhoto] = useState(null);

  const takePhoto = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: false,
        resultType: CameraResultType.Base64, // Changed to Base64 for easier handling
        source: CameraSource.Camera,
      });

      setPhoto(image.base64String);
      
      // Convert Base64 to File object for upload
      if (image.base64String) {
        const blob = await fetch(`data:image/jpeg;base64,${image.base64String}`).then(r => r.blob());
        const file = new File([blob], `photo_${Date.now()}.jpg`, { type: 'image/jpeg' });
        
        // Upload to our existing endpoint
        const formData = new FormData();
        formData.append('files', file);
        formData.append('symbol', symbol);

        const response = await fetch('/upload-and-process', {
          method: 'POST',
          body: formData
        });

        const result = await response.json();
        if (onPhotoTaken) {
          onPhotoTaken(result);
        }
      }

    } catch (error) {
      console.error('Camera error:', error);
    }
  };

  return (
    <div>
      <Button variant="primary" onClick={takePhoto}>
        <i className="fas fa-camera"></i> Take Photo
      </Button>
      {photo && (
        <div className="mt-3">
          <img 
            src={`data:image/jpeg;base64,${photo}`} 
            alt="Captured" 
            style={{ maxWidth: '100%', borderRadius: '4px' }} 
          />
        </div>
      )}
    </div>
  );
}

export default CameraButton; 