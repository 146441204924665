import React, { useState, useEffect } from 'react';
import { Modal, Card } from 'react-bootstrap';

const AnalysisWindow = ({ symbol, isOpen, onClose }) => {
    const [analysis, setAnalysis] = useState([]);

    useEffect(() => {
        if (isOpen && symbol) {
            startAnalysis();
        }
        return () => setAnalysis([]); // Clear on close
    }, [isOpen, symbol]);

    const startAnalysis = async () => {
        try {
            console.log("Starting analysis for:", symbol);
            const eventSource = new EventSource(`/full-analysis?ticker=${symbol}`);
            
            eventSource.onmessage = (event) => {
                console.log("Received message:", event.data);
                const data = JSON.parse(event.data);
                setAnalysis(prev => {
                    console.log("Current analysis:", prev);
                    console.log("Adding new content:", data.content);
                    return [...prev, data.content];
                });
            };
            
            eventSource.onerror = (error) => {
                console.error('SSE error:', error);
                eventSource.close();
            };
            
        } catch (error) {
            console.error('Analysis failed:', error);
        }
    };

    return (
        <Modal show={isOpen} onHide={onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Full Analysis: {symbol}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Card.Body>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                            {analysis.join('\n')}
                        </pre>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    );
};

export default AnalysisWindow; 