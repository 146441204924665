import React from 'react';
import './App.css';
import StockSearch from './components/StockSearch';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import DataRoom from './components/DataRoom';

function App() {
    return (
        <div className="App">
            <StockSearch />
            <Footer />
        </div>
    );
}

export default App;
