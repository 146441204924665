import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <p className="support-text">
                For support, please email <a href="mailto:support@stockato.ai">support@stockato.ai</a>. We will generally respond in 24 hours.
            </p>
            <p className="privacy-text">
                Privacy Policy: We collect stock ticker symbols and chat interactions to provide and improve our services. Your data is protected with industry-standard security measures.
            </p>
        </footer>
    );
}

export default Footer; 