import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Modal } from 'react-bootstrap';
import CameraButton from './CameraButton';

const DataRoom = ({ symbol }) => {
    const [files, setFiles] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    // Fetch files for this stock when component mounts or symbol changes
    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await fetch(`/files/${symbol}`);
                const data = await response.json();
                setFiles(data.files || []);
            } catch (err) {
                console.error('Failed to fetch files:', err);
            }
        };

        if (symbol) {
            fetchFiles();
        }
    }, [symbol]);

    const onDrop = useCallback(async (acceptedFiles) => {
        setProcessing(true);
        const formData = new FormData();
        formData.append('symbol', symbol);
        
        acceptedFiles.forEach(file => {
            formData.append('files', file);
        });

        try {
            const response = await fetch('/upload-and-process', {
                method: 'POST',
                body: formData
            });
            const data = await response.json();
            setFiles(prev => [...prev, ...data.files]);
        } catch (err) {
            console.error('Upload failed:', err);
        } finally {
            setProcessing(false);
        }
    }, [symbol]);

    const { getRootProps, getInputProps } = useDropzone({ 
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png']
        }
    });

    const handleImageClick = (file) => {
        setSelectedImage(file);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    const handleDelete = async (file) => {
        if (window.confirm('Are you sure you want to delete this image?')) {
            try {
                const response = await fetch(`/files/${symbol}/${file.key.split('/').slice(1).join('/')}`, {
                    method: 'DELETE'
                });
                
                if (response.ok) {
                    // Remove file from state
                    setFiles(files.filter(f => f.key !== file.key));
                } else {
                    console.error('Delete failed');
                }
            } catch (err) {
                console.error('Delete failed:', err);
            }
        }
    };

    return (
        <div>
            <h2>{symbol} Data Room</h2>
            
            {/* Add Camera Button */}
            <div className="mb-4">
                <CameraButton 
                    symbol={symbol} 
                    onPhotoTaken={(result) => {
                        // Add new files to state
                        setFiles(prev => [...prev, ...result.files]);
                    }}
                />
            </div>

            <div {...getRootProps()} style={dropzoneStyles}>
                <input {...getInputProps()} />
                <p>Drag & drop images here, or click to select</p>
                <p>Supported formats: JPEG, PNG</p>
            </div>
            {processing && <p>Processing images...</p>}
            <div style={imageGridStyles}>
                {files.map(file => (
                    <div key={file.key} style={imageContainerStyles}>
                        <img 
                            src={file.url} 
                            alt={file.name}
                            style={{...imageStyles, cursor: 'pointer'}}
                            onClick={() => handleImageClick(file)}
                        />
                        <div style={imageActionsStyles}>
                            <p>{file.name}</p>
                            <button 
                                onClick={(e) => {
                                    e.stopPropagation();  // Prevent modal from opening
                                    handleDelete(file);
                                }}
                                className="btn btn-danger btn-sm"
                            >
                                Delete
                            </button>
                        </div>
                        {file.metadata && (
                            <div>
                                <p>People detected: {file.metadata.people_count}</p>
                                <p>Faces blurred: {file.metadata.faces_blurred}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <Modal show={!!selectedImage} onHide={handleCloseModal} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{selectedImage?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedImage && (
                        <div style={modalImageContainerStyles}>
                            <img
                                src={selectedImage.url}
                                alt={selectedImage.name}
                                style={modalImageStyles}
                            />
                            {selectedImage.metadata && (
                                <div style={modalMetadataStyles}>
                                    <p>People detected: {selectedImage.metadata.people_count}</p>
                                </div>
                            )}
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: '20px'
};

const imageGridStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: '20px',
    padding: '20px'
};

const imageContainerStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
};

const imageStyles = {
    width: '100%',
    height: '200px',
    objectFit: 'contain',
    marginBottom: '10px'
};

const modalImageContainerStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
};

const modalImageStyles = {
    maxWidth: '100%',
    maxHeight: '80vh',
    objectFit: 'contain'
};

const modalMetadataStyles = {
    marginTop: '1rem',
    textAlign: 'center'
};

const imageActionsStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    marginTop: '8px'
};

export default DataRoom; 