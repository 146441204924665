import React, { useState, useEffect, useRef } from 'react';
import { Card, Form, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import AnalysisWindow from './AnalysisWindow';
import { ArrowsFullscreen, ArrowsAngleContract } from 'react-bootstrap-icons';

const ChatBox = ({ ticker }) => {
    const [chatMessage, setChatMessage] = useState('');
    const [chatResponse, setChatResponse] = useState([]);
    const chatBoxRef = useRef(null);  // Ref for the chat display area
    const [showAnalysis, setShowAnalysis] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const inputRef = useRef(null);

    const handleChatSubmit = async (e) => {
        e.preventDefault();
        if (!chatMessage.trim()) return;

        console.log("Starting chat submission");  // NEW
        setChatResponse(prev => [...prev, { user: 'User', message: chatMessage }]);
        setChatMessage('');

        try {
            console.log("Making fetch request");  // NEW
            if (chatMessage.toLowerCase().includes('full analysis')) {
                const response = await fetch('/chat', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ message: chatMessage, ticker }),
                });

                const reader = response.body.getReader();
                const decoder = new TextDecoder();

                while (true) {
                    console.log("Reading chunk...");  // NEW
                    const { value, done } = await reader.read();
                    if (done) {
                        console.log("Stream complete");  // NEW
                        break;
                    }
                    
                    const chunk = decoder.decode(value);
                    console.log("Raw chunk:", chunk);  // MODIFIED
                    
                    const lines = chunk.split('\n');
                    console.log("Split lines:", lines);  // NEW
                    
                    for (const line of lines) {
                        console.log("Processing line:", line);  // NEW
                        if (line.startsWith('data: ')) {
                            const parsed = JSON.parse(line.slice(5));
                            console.log("Parsed data:", parsed);  // NEW
                            
                            setChatResponse(prev => {
                                const newState = [...prev, { user: 'Ato', message: parsed.response }];
                                console.log("New state:", newState);  // NEW
                                return newState;
                            });
                        } else {
                            console.log("Line doesn't start with data:", line);  // NEW
                        }
                    }
                }
            } else {
                const response = await axios.post('/chat', {
                    message: chatMessage,
                    ticker,
                });
                console.log("Got response:", response.status);
                setChatResponse(prev => [...prev, { user: 'Ato', message: response.data.response }]);
            }
        } catch (err) {
            console.error("Detailed error:", err);  // MODIFIED
            setChatResponse(prev => [...prev, { 
                user: 'Ato', 
                message: 'Error occurred while fetching chat response.' 
            }]);
        }
    };

    const scrollToBottom = () => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;  // Scroll only the chat box
        }
    };

    useEffect(() => {
        scrollToBottom();  // Scroll to bottom whenever chatResponse updates
    }, [chatResponse]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [chatMessage]);

    useEffect(() => {
        setChatResponse([]);
    }, [ticker]);

    const handleExpand = () => {
        setChatResponse([]);
        setIsExpanded(true);
    };

    const handleMinimize = () => {
        setChatResponse([]);
        setIsExpanded(false);
    };

    const handleFullAnalysis = () => {
        setShowAnalysis(true);
    };

    const ChatContent = () => (
        <>
            <div className="d-flex justify-content-between align-items-center p-2">
                <h4 className="m-0">Stockato AI</h4>
                <ArrowsFullscreen 
                    size={20} 
                    onClick={handleExpand}
                    style={{ cursor: 'pointer' }} 
                />
            </div>
            <div 
                ref={chatBoxRef}
                style={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    padding: '1rem',
                    borderBottom: '1px solid #ccc',
                    maxWidth: '100%',  // Constrain width
                    wordBreak: 'break-word'  // Break long words
                }}
            >
                {chatResponse.length > 0 ? (
                    chatResponse.map((chat, index) => (
                        <div key={index} className="chat-response" style={{
                            maxWidth: '100%',  // Constrain message width
                            overflow: 'hidden'  // Hide overflow
                        }}>
                            <strong>{chat.user}:</strong>
                            <p style={{ 
                                whiteSpace: 'pre-wrap', 
                                margin: 0,
                                overflowWrap: 'break-word'  // Break long words
                            }}>
                                {chat.message}
                            </p>
                        </div>
                    ))
                ) : (
                    <p>Ask a question about the stock! Or we can:
                    <br/>• analyze Twitter sentiment
                    <br/>• analyze Reddit discussions
                    <br/>• analyze options market
                    </p>
                )}
            </div>
            <Form onSubmit={handleChatSubmit} className="p-2">
                <Form.Group className="mb-2">
                    <Form.Control
                        ref={inputRef}
                        type="text"
                        placeholder="Ask a question about the stock"
                        value={chatMessage}
                        onChange={(e) => {
                            setChatMessage(e.target.value);
                            e.target.focus();
                        }}
                    />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100">Send</Button>
            </Form>
            <Button 
                onClick={handleFullAnalysis}
                variant="secondary"
                className="mx-2 mb-2"
            >
                Full Analysis
            </Button>
        </>
    );

    return (
        <>
            {isExpanded ? (
                <Modal 
                    show={isExpanded} 
                    onHide={handleMinimize}
                    size="lg"
                    dialogClassName="modal-90w"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="d-flex justify-content-between align-items-center w-100">
                                Chat with Stockato AI
                                <ArrowsAngleContract 
                                    size={20}
                                    onClick={() => setIsExpanded(false)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: 0 }}>
                        <div style={{ 
                            height: '90vh',  // Use more vertical space
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <ChatContent />
                        </div>
                    </Modal.Body>
                </Modal>
            ) : (
                <Card className="mb-4" style={{ 
                    height: '700px', 
                    display: 'flex', 
                    flexDirection: 'column',
                    overflow: 'hidden',  // Add this
                    width: '100%'        // Add this
                }}>
                    <Card.Body style={{ 
                        padding: 0, 
                        display: 'flex', 
                        flexDirection: 'column', 
                        flexGrow: 1,
                        overflow: 'hidden'  // Add this
                    }}>
                        <ChatContent />
                    </Card.Body>
                </Card>
            )}

            <AnalysisWindow 
                symbol={ticker}
                isOpen={showAnalysis}
                onClose={() => setShowAnalysis(false)}
            />
        </>
    );
};

export default ChatBox;
